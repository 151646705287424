/** @jsx jsx */
import React from 'react'
import { css, jsx, ThemeProvider } from '@emotion/react'
import Layout from '../components/Layout'
import { Column } from '@swp/components/lib/Column'
import { ColumnWrap } from '@swp/components/lib/Column'
import { ColumnWrapper } from '@swp/components/lib/Column'
import { Text } from '@swp/components/lib/Text'
import { Menu } from '@swp/components/lib/Menu'
import { Image } from '@swp/components/lib/Image'
import { MenuWrap } from '@swp/components/lib/Menu'
import { MenuButton } from '@swp/components/lib/Menu'
import { MenuWrapBox } from '@swp/components/lib/Menu'
import { MenuButtonClose } from '@swp/components/lib/Menu'
import { Button } from '@swp/components/lib/Button'
import { Title } from '@swp/components/lib/Title'
import { ContactForm } from '@swp/components/lib/ContactForm'

const theme = {
  "id": 127,
  "colors": {
    "front1": "rgba(232,232,232,1)",
    "front2": "rgba(255,255,255,1)",
    "front3": "rgba(0,0,0,1)",
    "front4": "rgba(0,0,0,1)",
    "back1": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)",
    "back3": "rgba(236,236,236,1)",
    "back4": "rgba(178,178,178,1)"
  },
  "variables": {
    "color-variable-1": "rgba(251,201,0,1)",
    "color-variable-2": "rgba(242,243,245,1)",
    "color-variable-btn-1": "rgba(255,255,255,1)",
    "color-variable-btn-2": "rgba(0,0,0,1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function Page(props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout title={"Škoda Superb II 1.6TDI limuzína"}>
        <Column name={"n077oaf9u4g"} style={{"backgroundColor":"rgba(0,0,0,1)"}}>
        </Column>


        <Column style={{"backgroundColor":"rgba(0,0,0,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full pl--10 pr--10" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"Jsme Vám k dispozici od <span style=\"color: rgb(251, 201, 0);\">8:00</span> do <span style=\"color: rgb(251, 201, 0);\">16:00</span>, PO—PÁ"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--right fs--14 w--600 text-box--invert" content={"info@autobezproblemu.cz&nbsp; <span style=\"color: rgb(251, 201, 0);\">/</span>   +420 555 333 961 "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--02 pt--02" menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <Menu className="--full pb--06 pt--06" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --left" style={{"maxWidth":""}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/2058/328ed3f63e934c1eabe5e72de6202b43.svg"} svg={false} href={"https://autobezproblemu.cz"} sizes={"100vw"} style={{"maxWidth":170}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#vozy"} target={""} content={"Nyní dostupné vozy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#jak-to-funguje"} target={""} content={"Jak to funguje"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn1 btn-box--shape2 fs--18 w--600 pl--08 pr--08" innerClassName="pt--12 pb--12 pl--20 pr--20" href={"/poptat"} pbtn={null} target={""} content={"Poptat kalkulaci"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center pb--20 pt--20" name={"vcmrrkfk3o"} style={{"backgroundColor":"rgba(242,243,245,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--25 pl--30 pr--30 pt--30">
              
              <Title className="title-box fs--62 pl--0" content={"Škoda Superb II<br>"}>
              </Title>

              <Text className="text-box fs--18 mt--0 pl--0" style={{"maxWidth":799}} content={" 1.6 TDI Limuzína"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--0 pt--0" name={"a31ay4ybvfm"} layout={"l8"}>
        </Column>


        <Column className="pb--0 pt--0" name={"gel3xemv8o"} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--10" name={"vcmrrkfk3o"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--50" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box mb--0 pl--0 pr--0" content={"Režim&nbsp;&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp; FULL&nbsp;&nbsp;</span>"}>
              </Title>

              <Text className="text-box mt--20 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">30 000 Kč</span><br>Počet plateb: <span style=\"font-weight: bold;\">36</span><br>Výše platby: <span style=\"font-weight: bold;\">8 000Kč</span> + pojištění<br>"}>
              </Text>

              <Text className="text-box mt--16 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">40 000 Kč</span>  !!AKČNÍ CENA!! <br>Počet plateb: <span style=\"font-weight: bold;\">12</span><br>Výše platby: <span style=\"font-weight: bold;\">14 000 Kč</span> + pojištění<br>"}>
              </Text>

              <Text className="text-box mt--16 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">30 000 Kč </span>!!AKČNÍ CENA!!<br>Počet plateb: <span style=\"font-weight: bold;\">24</span><br>výše platby: <span style=\"font-weight: bold;\">10 900 Kč</span> + pojištění<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box mb--0 pl--0 pr--0" content={"Režim LITE"}>
              </Title>

              <Text className="text-box mt--20 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">30 000 Kč</span><br>Počet plateb: <span style=\"font-weight: bold;\">36</span><br>Výše platby: <span style=\"font-weight: bold;\">7 000 Kč</span> + pojištění<br>"}>
              </Text>

              <Text className="text-box mt--16 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">40 000 Kč</span>  !!AKČNÍ CENA!! <br>Počet plateb:&nbsp;<span style=\"font-weight: bold;\">12</span><br>Výše platby:<span style=\"font-weight: bold;\"> 13 300 Kč</span> + pojištění<br>"}>
              </Text>

              <Text className="text-box mt--16 pl--0 pr--0" content={"První platba: <span style=\"font-weight: bold;\">30 000 Kč</span> !!AKČNÍ CENA!!<br>Počet plateb: <span style=\"font-weight: bold;\">24</span><br>výše platby: <span style=\"font-weight: bold;\">9 900 Kč </span>+ pojištění<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--10" style={{"backgroundColor":"rgba(242,243,245,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mt--50" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box mb--0 pl--0 pr--0" content={"Poptávka"}>
              </Title>

              <ContactForm className="--shape2 --style3 mt--30" style={{"maxWidth":1287}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Město a PSČ","type":"text","placeholder":"Město a PSČ"},{"name":"Optimálni výše měsíční splátky","type":"text","placeholder":"Optimálni výše měsíční splátky"},{"name":"Maximální výše 1. platby","type":"text","placeholder":"Maximální výše 1. platby"},{"name":"Název auta, o které máte zájem a případné dotazy","type":"textarea","required":false,"placeholder":"Název auta, o které máte zájem a případné dotazy"},{"name":"Odeslat poptávku","type":"submit"}]} styleFlag={3}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" style={{"backgroundColor":"rgba(251,201,0,1)"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --left el--2 mb--10 mt--10" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0">
              
              <Title className="title-box fs--62 pl--0" style={{"maxWidth":534}} content={"Všechno bereme na sebe!<br>"}>
              </Title>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Neplatíte&nbsp;<span style=\"font-weight: bold;\">servis, pneumatiky, opravy!</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Bez nahlížení&nbsp;<span style=\"font-weight: bold;\">do registru!</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Náhradní vůz&nbsp;<span style=\"font-weight: bold;\">zdarma!</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":517}}>
              
              <Text className="text-box fs--18 lh--2 mt--06 pb--0 pl--0 pr--0 pt--0" content={"Ve režimu FULL všechno bereme na sebe! <span style=\"font-weight: bold;\">Našim autům věříme a tak po dobu financování jde na nás veškerý servis, pneumatiky, opravy</span> - Vy neplatíte nic!&nbsp;V praxi je to tak, že se Vám například zničí převodovka a o vše se postaráme my, na náš účet! Za normálních okolností byste museli koupit ze svého převodovku. To se Vám s námi nestane.<br>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 pl--0" content={"Online poptávka&nbsp;&nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={true} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=2000x_.jpeg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=1400x_.jpeg);
          }
        `}>
          
          <ColumnWrap className="column__flex --center el--3 mb--08 mt--08" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Vyberte si z vozidel skladem."}>
              </Title>

              <Text className="text-box mt--10" content={"Dodání ihned."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"https://autobezproblemu.cz/#vozy"} content={"Prohlížet vozy"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Nechte si<br>vytvořit nabídku.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Financování na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"/poptat"} content={"Poptat nabídku"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Máte dotaz?<br>Kontaktujte nás.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Financování na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--cColor2" href={"mailto:info@autobezproblemu.cz"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50">
          
          <ColumnWrap className="column__flex --left el--4 pb--10 pt--10" anim={null} animS={null} style={{"maxWidth":1460}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/1c894a4b118646a0b810f1f26828fc71.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box fs--26 w--600 lh--14" style={{"maxWidth":310}} content={"Přehoďte veškeré starosti s auty na nás!\n<br>"} uppercase={false}>
              </Text>

              <Text className="text-box mt--12" style={{"maxWidth":336}} content={"+420 555 333 961 info@autobezproblemu.cz<br>8:00-16:00 PO-PÁ"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":410}}>
              
              <Text className="text-box text-box--right fs--26 w--600 lh--14" style={{"maxWidth":310}} content={"Máte zájem o auto?<br>"} uppercase={false}>
              </Text>

              <Text className="text-box mt--02" style={{"maxWidth":336}} content={"Na všem se domluvíme."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"/poptat"} content={"Poptat ONLINE&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(0,0,0,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full pl--10 pr--10" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"Auto bez problémů&nbsp;<span style=\"color: rgb(251, 201, 0);\">(C) 2021</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--right fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\">Tvorba webových stránek</a>&nbsp; Saywebpage.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeProvider>
  )
}